cpt.generic = {
    init: function(){
        $("form#searchTop select").change(function () {
            $('#searchTop').submit();
        });

        $("form#frmSearchTop select").change(function () {
            $('#frmSearchTop').submit();
        });
    },
    setBodyPanelHt: function(){
        // 937-24-84-72
        var bodyHt = $(window).height() - $('.topBar').height() - $('.navbar').height() - $('footer').height() - 25;
        $('.bodyPanel').css('min-height', bodyHt + 'px' );
    },

    isLoggedIn: function(showAlert){
        $.ajax({
            type: 'GET',
            url: '/index.php?_spAction=isLoggedInWWW&showHTML=0',
            dataType: 'json',
            success: function(json) {
                res = json.status;
                if (json.status == 0 && showAlert){
                	Util.alert('Please login as a member before you proceed!');
                    Util.hideProgressInd();
                }
            },
            data: {},
            async: false
        });

        return res;
    }
};

const $navbar = $('#mainNav');

$('a.slide[href^="#"]').on('click', function(e) {
    e.preventDefault();
    const scrollTop = $($(this).attr('href')).position().top - $navbar.outerHeight();
    $('html, body').animate({ scrollTop }, 'slow');
});

$(function () {
	setTimeout(function(){
		$('body').addClass('loaded');
	}, 0);

    if ($('.topBar').length > 0){
		$('body').addClass('hasTopBar');
    }

    if ($('.topBanner').length > 0){
		$('body').addClass('hasTopBanner');
    }

    $('.scrollup').click(function(e){
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });

    cpt.generic.setBodyPanelHt();

    $(window).scroll(function(){
        var $body = $('body');
        var $menu = $('.navbar');

        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
            $menu.addClass('scrolled');
            $body.addClass('scrolled');
        } else {
            $('.scrollup').fadeOut();
            $menu.removeClass('scrolled');
            $body.removeClass('scrolled');
        }
        
        if($menu.length > 0) {
            var menuOffsetY = $menu.offset().top;
        }
    });

    //lazy load imgAsBg or not
	setTimeout(function(){
        var hasLazyLoadImages = Cfg.data['cp.hasLazyLoadImages'] ? Cfg.data['cp.hasLazyLoadImages'] : false;
        if (hasLazyLoadImages) {
            // lazy load background images
            $('.imgAsBg').lazy();

            $('.imgAsBg').each(function () {
                var bgPosition = $(this).attr('bgPosition');

                if (bgPosition != '') {
                    $(this).css({
                        'background-position': bgPosition
                    });
                }
            });

        } else {
            $('.imgAsBg').each(function () {
                var pic = $(this).attr('pic');
                var bgPosition = $(this).attr('bgPosition');
                var minh = $(this).attr('minh');
                var opacity = $(this).attr('opacity');

                $(this).css({
                    'background-image': 'url("' + pic + '")'
                });

                if (bgPosition != '') {
                    $(this).css({
                        'background-position': bgPosition
                    });
                }

                if (minh != '') {
                    $(this).css({
                        'min-height': minh
                    });
                }
            });
        }
    }, 0);

    $('.sCarousel').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        speed: 500,
        autoplay: true,
        easing: 'swing',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
    .on("afterChange", function (e, slick) {
        $('.sCarousel').slick("setOption", "autoplaySpeed", 7000);
    });
});


$(function(){
    AOS.init();

    $('.tltBig').textillate({
        // in animation settings
        in: {
            delay: 20,
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });

    $('.tltSmall').textillate({
        initialDelay: 200,
        // in animation settings
        in: {
            effect: 'fadeInRightBig',
            delay: 20
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });

    $('.tltBannerTitle').textillate({
        initialDelay: 200,
        // in animation settings
        in: {
            effect: 'fadeInRightBig',
            delay: 20
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });
});


$(document).ready(function() {
    // Open external links in a new window or tab
    $(document).on('click', 'a[rel$="external"]', function() {
      $(this).attr('target', "_blank");
    });
    $(document).on('click', 'a[href$=".pdf"]', function() {
      $(this).attr('target', "_blank");
    });

	$('a').each(function() {
		var a = new RegExp('/' + window.location.host + '/');
        var href = this.href;
        var hrefJqObj = $(this);

        var loadUrlInternally = hrefJqObj.hasClass('load-url-internally');
        // if it's external url and doesn't have the class load-url-internally
		if(!a.test(href) && !loadUrlInternally && href.substr(0, 10) != 'javascript') {
			$(this).click(function(event) {
				event.preventDefault();
				event.stopPropagation();
                window.open(this.href, '_blank');
			}
			);
		}
	});

    // Javascript to enable link to tab
    var url = document.location.toString();
    if (url.match('#')) {
        var hash = url.split('#')[1];
        $('.nav-tabs a[href="#' + hash + '"]').tab('show');

        // Change hash for page-reload
        $('.nav-tabs a').on('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash;
        });

        if ($("button[data-target='#" + hash + "']").length > 0){
            $("button[data-target='#" + hash + "']").trigger('click');
            setTimeout(function(){
                $('html,body').animate({
                    scrollTop: $(window).scrollTop() - 200
                });
            }, 1000);
        }
    }
});

$(".serachSubmitGo").click(function(){
    $(".searchBar").hide();
});
