Util.createCPObject('cpm.general.contact');

cpm.general.contact = {
    init: function(){
        $('.btnRegister').click(function(){
            $('#registerInto').hide();
            $('#registerWrapper').removeClass('d-none');
        });
    }
}
