$(document).ready(function() {

    //Process Search Toggle
    $('.searchToggle').click(function(){
        $('#translateHeaderBox').slideUp( 250, function() {
            // Animation complete.
        });
        $('#headerSearchBox').slideToggle( 250, function() {
            // Animation complete.
            //Set user focus into the Search Field:
            $(this).toggleClass('openPanel');
            if($(this).hasClass('openPanel')){
                $('#s-header').focus(); 
            }
        });

    });

	//Toggle mobile menu submenus:
    $('.navbar-nav .dropdown .caret').click(function(e){
        $(this).toggleClass('open');
        e.preventDefault();
        $(this).closest('.dropdown').find('.dropdown-menu').slideToggle(250, function() {
            // Animation complete.
        });
        return false;
    });

    $('.accordionTitle').click(function(){
        $(this).toggleClass('active');
        $(this).closest('.singleAccordion').find('.accordionContent').slideToggle( 250, function() {
        // Animation complete.
        });
    });

    $('body.cpln-cht').livequery(function(){
        var text = $('#footerContactDetails').html();
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-right mt10",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": 0,
            "extendedTimeOut": 0,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "tapToDismiss": false
          };

        toastr.error(text);
    });

    $("a[href*='youtube']").livequery(function() {
        $("a[href*='youtube']").colorbox({iframe:true, innerWidth:640, innerHeight:390});
    });

    autoPlayYouTubeModal();

  //FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
  function autoPlayYouTubeModal() {
      var trigger = $("body").find('[data-toggle="modal"]');
      trigger.click(function () {
          var theModal = $(this).data("target"),
              videoSRC = $(this).attr("data-theVideo"),
              videoSRCauto = videoSRC + "?autoplay=1";
          $(theModal + ' iframe').attr('src', videoSRCauto);
          $(theModal + ' button.close').click(function () {
              $(theModal + ' iframe').attr('src', videoSRC);
          });
      });
  }

});