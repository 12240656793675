Util.createCPObject('cpm.ecommerce.wishlist');

cpm.ecommerce.wishlist = {
    init: function(){
    	$('.removeFromWishlist').click(function(e){
            cpm.ecommerce.wishlist.removeFromWishlist.call(this, e);
        })
    },

    removeFromWishlist: function(e){
        e.preventDefault();
        var parent = $(this).closest('.product');
        var productId = parent.attr('itemid');

        var msg = "Are you sure you want to remove this item from the wishlist?";

        Util.confirm(msg, function() {
            Util.showProgressInd();
            var url = '/index.php?module=ecommerce_wishlist&_spAction=removeFromWishlist';
            $.get(url, {productId: productId}, function(json){
                document.location = document.location;
            });

        }, {
            title: 'Remove from Wishlist'
        });
    }
}
