/*Util.createCPObject('cpm.ecommerce.basket');

cpm.ecommerce.basket = {
    init: function(){
    	$('.btnRemoveFromCart').click(function(e){
    		cpm.ecommerce.basket.removeFromCart.call(this, e);
    	})

    	$('.btnConfirmOrder').click(function(e){
    		cpm.ecommerce.basket.confirmOrder.call(this, e);
    	})
    },

    removeFromCart: function(e){
        e.preventDefault();
        Util.showProgressInd();
		var basketId = $(this).attr('basketId');
        var url = '/index.php?module=ecommerce_basket&_spAction=removeFromCart';
        $.getJSON(url, {basketId: basketId}, function(json){
            document.location = document.location;
        });
    },

    confirmOrder: function(e){
        e.preventDefault();
        Util.showProgressInd();
        if (cpt.generic.isLoggedIn()){
            var url = '/index.php?module=ecommerce_order&_spAction=confirmOrder';
            $.getJSON(url, function(json){
                document.location = json.confirmOrderUrl;
            });
        }
    },
}*/

$(function() {

    $('.submitBsForm').on('click', function(e){
        e.preventDefault();

        var frmId = $(this).attr('frmId');
        if(frmId){
            $('#' + frmId).submit();
        } else {
            // for backward compatibilty
            $(this).closest('form.bsForm').submit();
        }
    });

    $('.btnConfirmOrder').click(function(){
        basket.confirmOrder();
    })

    $('.btnRemoveFromCart').click(function(){
        var basketId = $(this).attr('basketId');
        basket.removeFromCart(basketId);
    })

    var basket = {
        confirmOrder: function(){
            Util.showProgressInd();
            //if (cpt.generic.isLoggedIn()){
                var url = '/index.php?module=ecommerce_order&_spAction=confirmOrder';
                $.getJSON(url, function(json){
                    document.location = json.confirmOrderUrl;
                });
            //}
        },

        removeFromCart: function(basketId){
            Util.showProgressInd();
            var url = '/index.php?module=ecommerce_basket&_spAction=removeFromCart';
            $.getJSON(url, {basketId: basketId}, function(json){
                document.location = document.location;
            });
        }
    }
});