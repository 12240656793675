Util.createCPObject('cpm.ecommerce.stockists');

cpm.ecommerce.stockists = {
    init: function(){
        $('#fld_category_id').change(function(){
            cpm.ecommerce.stockists.getStoresList();
        });

        $('#fld_address_country').change(function(){
            cpm.ecommerce.stockists.getStoresList();
        });
    },

    getStoresList: function(){
           var address_country = $('#fld_address_country').val();   
           var category_id = $('#fld_category_id').val();   
        
            if(address_country != "" && category_id != "" ){
                Util.showProgressInd();
                var url = "/index.php?_spAction=storesList&module=ecommerce_stockists&showHTML=0";
                $.get(url, {address_country: address_country, category_id: category_id}, function (data){
                    $('#storesList').html(data);   
                    $('#wrapMiddle').height('auto');    
                    Util.hideProgressInd();
                    //Util.equalHeight($('.equalColumn'));
                });
            }else{
                $('#storesList').html("<span style='color:red;'>Please choose both Market Focus & Country</span>");
            }        
    }
}
