/*Util.createCPObject('cpm.ecommerce.product');

cpm.ecommerce.product = {
    init: function(){

		$(".show-more a").livequery('click', function (e){
		
		    var ele = document.getElementById('text-content');
		    var text = $(this).html();
		    var textindex = document.getElementById('showDisplayText');

			if(text == 'Show more'){
				$(".text-content").removeClass('short-text').addClass('full-text');
		   	    textindex.innerHTML = 'Show Less';
			}else{
				$(".text-content").removeClass('full-text').addClass('short-text');
				textindex.innerHTML = 'Show more';
			}
		});


    },

}*/

$(function() {

	$('.btnAddToCart').click(function(){
        var itemId = $(this).attr('itemId');
        product.getAddToCart(itemId);
	});

    var product = {
	    getAddToCart: function(itemId){
	        Util.showProgressInd();
	        var url = '/index.php?module=ecommerce_basket&_spAction=addToCart';
	        $.getJSON(url, {itemId: itemId}, function(json){
	            var basketUrl = json.basketUrl;
	            document.location = basketUrl;
	            Util.hideProgressInd();
	        });
        }
    }

});