Util.createCPObject('cpm.general.address');

cpm.general.address = {
    init: function(){
        $('#fld_address_id').change(function(){
            var address_id = $(this).val();

            var url = "/index.php?module=general_address&address_id=" + address_id + "&_spAction=wishlistUrl&showHTML=0";
            $.get(url, function(data){
                document.location = data;
            });
        });

        $('.removeFromWishlist').click(function(e){
            e.preventDefault();

            var wrapper = $(this).closest('.wrapper');
            var wl_id = $(this).attr('wl_id');
            var g_id = $(this).attr('g_id');

            var url = "/index.php?module=general_address&address_id=" + wl_id + "&gallery_id=" + g_id + "&_spAction=removeFromWishlist&showHTML=0";
            $.get(url, function(data){
                wrapper.remove();
            });
        });

        $('.deleteAddress').click(function(e){
            e.preventDefault();
            var address_id = $(this).attr('address_id');

            var msg = "Are you sure you want to delete this address? You cannot undo this action";
            var res = confirm(msg);
            if (res == true) {
                var url = "/index.php?module=general_address&address_id=" + address_id + "&_spAction=deleteAddress&showHTML=0";
                $.get(url, function(){
                    document.location = document.location;
                });
            } else {
            }
        });
    },

    cbAfterNew: function () {
        document.location = document.location;
    }
}
